import React from "react";
import { MrbGrid } from "mrb/components/grid";
import { Image } from "common/components";
import { TorahAnytimeWizardLayout } from "application/torah-anytime/components";
import { MrbQuerySearchInput } from "mrb/components/input";
import { MrbPageHeader } from "mrb/components/layout";
import { MrbButton } from "mrb/components/button";
import { MrbPager } from "mrb/components/pager";
import { findIndex, isEmpty } from "lodash";
import { mergeCss } from "mrb/common/utils";
import { observer } from "mobx-react";
import { TorahAnyTimeLoader } from "application/torah-anytime/components/content-loader";
import { MrbRouterLink } from "mrb/core/infrastructure/router";
import { applicationDefaults } from "common/constants";

function TorahAnytimeSelectTopicsTemplate({ torahAnytimeSelectTopicsViewStore, torahAnytimeWizardViewStore, t }) {
    const { gridStore, torahAnytimeSelectionViewStore, queryUtility } = torahAnytimeSelectTopicsViewStore;
    return (
        <React.Fragment>
            <MrbPageHeader classNameExtend="c-page__header--secondary">
                <div className="u-display--flex u-display--flex--jc--space-between u-display--flex--ai--center u-gap--lrg">
                    <MrbRouterLink
                        routeName="master.application.home.torah-anytime"
                        params={{ id: torahAnytimeWizardViewStore.podcastId }}
                    >
                        <i className="u-icon u-icon--med u-icon--back u-mar--right--tny"></i>
                        Back
                    </MrbRouterLink>
                    <MrbQuerySearchInput
                        className="c-input c-input--search c-input--search--lrg c-input--base jq-onboarding-search"
                        queryUtility={queryUtility}
                        placeholder="APPLICATION.TORAH_ANYTIME.EDIT.SELECT_TOPICS.SEARCH_FIELD_PLACEHOLDER"
                        showSearchButton={false}
                        t={t}
                        maxLength={applicationDefaults.maxCharactersLength}
                    />
                </div>
            </MrbPageHeader>
            <TorahAnytimeWizardLayout
                store={torahAnytimeWizardViewStore}
                controlsRenderer={(props) => (
                    <SelectTopicsWizardControls
                        {...props}
                        torahAnytimeSelectTopicsViewStore={torahAnytimeSelectTopicsViewStore}
                        t={t}
                    />
                )}
                t={t}
            >
                <div>
                    <MrbGrid
                        classNameOverride="c-home__list"
                        store={gridStore}
                        t={t}
                        loaderRender={() => <TorahAnyTimeLoader />}
                        cellRender={(props) => (
                            <TopicCell {...props} torahAnytimeSelectionViewStore={torahAnytimeSelectionViewStore} />
                        )}
                        emptyStateRender={() => <NoDataEmptyState t={t} queryUtility={queryUtility} />}
                        pagerRender={(props) => (
                            <MrbPager
                                {...props}
                                hideItemsPerPage={true}
                                hidePagerIfUnderLimit={queryUtility.filter.pageSize}
                            />
                        )}
                    />
                </div>
            </TorahAnytimeWizardLayout>
        </React.Fragment>
    );
}

const TopicCell = observer(function TopicCell({ item, torahAnytimeSelectionViewStore }) {
    const { selectedTopics, selectTopic, deselectTopic } = torahAnytimeSelectionViewStore;
    const isSelected = findIndex(selectedTopics, (selectedTopic) => selectedTopic.topicId === item.id) !== -1;
    return (
        <div className="c-home__card c-home__card--secondary c-card__torah">
            <div
                className="c-home__card__media"
                onClick={isSelected ? () => deselectTopic(item) : () => selectTopic(item)}
            >
                {isSelected ? <EnabledIndicator /> : <DisabledIndicator />}

                <div className="c-home__card__img-wrapper status--disabled">
                    <Image src={item.imageUrl} />
                </div>
            </div>
            <div className="c-home__card__title">{item.title}</div>
        </div>
    );
});

function EnabledIndicator() {
    return <div className="c-home__status c-home__status--secondary c-home__status--enabled"></div>;
}

function DisabledIndicator() {
    return <div className="c-home__status c-home__status--secondary c-home__status--disabled"></div>;
}

function NoDataEmptyState({ queryUtility, t }) {
    const { filter } = queryUtility;
    const title = isEmpty(filter.search)
        ? t("APPLICATION.TORAH_ANYTIME.EDIT.SELECT_TOPICS.NO_DATA.TITLE")
        : t("APPLICATION.TORAH_ANYTIME.EDIT.SELECT_TOPICS.NO_DATA.TITLE_WTH_SEARCH", { search: filter.search });
    return (
        <div className="c-card c-card--med c-card--primary">
            <div className="c-empty-state--no-results c-empty-state--no-results--lrg">
                <div className="u-mar--bottom--sml">
                    <i className="u-icon u-icon--xhuge u-icon--playlist-add"></i>
                </div>
                <div className="u-mar--bottom--med">
                    <h3>{title}</h3>
                    <p>{t("APPLICATION.TORAH_ANYTIME.EDIT.SELECT_SPEAKERS.NO_DATA.DESCRIPTION")}</p>
                </div>
            </div>
        </div>
    );
}

const SelectTopicsWizardControls = observer(function SelectTopicsWizardControls({
    store,
    torahAnytimeSelectTopicsViewStore,
    t,
}) {
    const { nextStep, currentStep, previousStep, hasPreviousStep } = store;
    const { isTopicSelectionEmpty } = torahAnytimeSelectTopicsViewStore;
    return (
        <div>
            <div className="u-separator--primary"></div>
            <div
                className={mergeCss(
                    "u-display--flex",
                    `u-display--flex--jc--${hasPreviousStep ? "space-between" : "flex-end"}`,
                    "u-mar--top--med"
                )}
            >
                {hasPreviousStep && (
                    <MrbButton
                        label={currentStep.backButtonLabel ? currentStep.backButtonLabel : "Back"}
                        t={t}
                        classNameOverride="c-btn c-btn--base c-btn--ghost"
                        onClick={previousStep}
                    />
                )}
                <div className="u-display--flex">
                    {isTopicSelectionEmpty && (
                        <div className="c-validation--info u-mar--right--sml">
                            <p className="c-validation__label">
                                {t("APPLICATION.TORAH_ANYTIME.EDIT.SELECT_TOPICS.VALIDATION.NOTHING_SELECTED")}
                            </p>
                        </div>
                    )}
                    <MrbButton
                        disabled={isTopicSelectionEmpty}
                        label={currentStep.nextButtonLabel ? currentStep.nextButtonLabel : "Continue"}
                        t={t}
                        onClick={nextStep}
                    />
                </div>
            </div>
        </div>
    );
});

export default observer(TorahAnytimeSelectTopicsTemplate);
