import { LocalizedForm } from "common/localization";

export default class DeviceEditForm extends LocalizedForm {
    constructor(hooks) {
        super(hooks);
    }

    setup() {
        return {
            fields: [
                {
                    name: "deviceName",
                    label: "APPLICATION.DEVICE_SETTINGS.EDIT.FIELDS.DEVICE_NAME_LABEL",
                    placeholder: "APPLICATION.DEVICE_SETTINGS.EDIT.FIELDS.DEVICE_NAME_PLACEHOLDER",
                    rules: "required|string|max:50",
                },
                {
                    name: "timeZone",
                    label: "APPLICATION.DEVICE_SETTINGS.EDIT.FIELDS.TIMEZONE_LABEL",
                    placeholder: "APPLICATION.DEVICE_SETTINGS.EDIT.FIELDS.TIMEZONE_PLACEHOLDER",
                    rules: "required|string",
                },
            ],
        };
    }
}
