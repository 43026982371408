import React from "react";
import { PropTypes } from "prop-types";
import { map } from "lodash";
import { observer } from "mobx-react";
import { NakiNigunHomeGridCell } from "application/naki-nigun/components";

function NakiNigunHomeCategoryTemplate({
    item,
    goTo,
    premiumSubscriptionStore,
    nakiNigunManageSelectedItemsViewStore,
    onCellClick,
}) {
    return (
        <div className="u-mar--bottom--sml">
            <p className="u-type--base u-type--wgt--bold u-mar--bottom--tny">
                {item.genreName !== null ? item.genreName : "General Content"}
            </p>
            <div className="u-display--flex u-gap--sml u-display--flex--wrap">
                {map(item.homeContents, (item) => (
                    <div>
                        <NakiNigunHomeGridCell
                            goTo={goTo}
                            premiumSubscriptionStore={premiumSubscriptionStore}
                            nakiNigunManageSelectedItemsViewStore={nakiNigunManageSelectedItemsViewStore}
                            item={item}
                            onCellClick={onCellClick}
                        />
                    </div>
                ))}
            </div>
        </div>
    );
}

NakiNigunHomeCategoryTemplate.propTypes = {
    onCellClick: PropTypes.func.isRequired,
    premiumSubscriptionStore: PropTypes.object.isRequired,
    goTo: PropTypes.func.isRequired,
    nakiNigunManageSelectedItemsViewStore: PropTypes.object.isRequired,
    item: PropTypes.object.isRequired,
};

export default observer(NakiNigunHomeCategoryTemplate);
