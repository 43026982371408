import React from "react";
import { PropTypes } from "prop-types";
import { HomeEmptyState, HomeListLoader, HomeOrderBySelect } from "application/home/components";
import {
    NakiNigunHomeCategory,
    NakiNigunManageSelectedItems,
    NakiNigunHomeFeatured,
} from "application/naki-nigun/components";
import { MrbInfiniteScrollGrid } from "mrb/components/infinite-scroll-grid";
import { observer } from "mobx-react";

function NakiNigunHomeMainViewTemplate({ nakiNigunHomeViewStore, t }) {
    const {
        gridStore,
        goTo,
        rootStore: { premiumSubscriptionStore },
        nakiNigunManageSelectedItemsViewStore,
        nakiNigunHomeFeaturedContentViewStore,
        orderBySelectStore,
    } = nakiNigunHomeViewStore;
    const { isBulk } = nakiNigunManageSelectedItemsViewStore;

    return (
        <React.Fragment>
            <div>
                <NakiNigunManageSelectedItems
                    nakiNigunManageSelectedItemsViewStore={nakiNigunManageSelectedItemsViewStore}
                    t={t}
                />
            </div>
            <div className="u-mar--bottom--med">
                <NakiNigunHomeFeatured
                    nakiNigunHomeFeaturedContentViewStore={nakiNigunHomeFeaturedContentViewStore}
                    nakiNigunManageSelectedItemsViewStore={nakiNigunManageSelectedItemsViewStore}
                    t={t}
                />
            </div>

            <div className="c-home__filter">
                <div className="c-home__filter__actions c-home__filter__actions--end">
                    <div className="c-home__filter__select">
                        <HomeOrderBySelect store={orderBySelectStore} t={t} />
                    </div>
                </div>
            </div>

            <MrbInfiniteScrollGrid
                store={gridStore}
                t={t}
                classNameOverride="c-home__list--secondary"
                loadMoreLoader={<HomeListLoader row={1} />}
                loaderRenderer={() => <HomeListLoader row={3} bulk={isBulk} />}
                cellRenderer={(props) => (
                    <NakiNigunHomeCategory
                        goTo={goTo}
                        premiumSubscriptionStore={premiumSubscriptionStore}
                        nakiNigunManageSelectedItemsViewStore={nakiNigunManageSelectedItemsViewStore}
                        {...props}
                    />
                )}
                emptyStateRenderer={() => <HomeEmptyState classNameExtend="c-empty-state--no-results--lrg" t={t} />}
            />
        </React.Fragment>
    );
}

NakiNigunHomeMainViewTemplate.propTypes = {
    nakiNigunHomeViewStore: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
};

export default observer(NakiNigunHomeMainViewTemplate);
